import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import * as UAParser from "ua-parser-js";
import { ToastService } from "../toast/toast.service";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class UtilsService {
  constructor(
    private readonly toast: ToastService,
    private readonly translate: TranslateService,
  ) {}

  public validateForm(form: FormGroup): void {
    form.markAllAsTouched();
    Object.keys(form.controls).forEach(key => {
      const control = form.get(key);
      control.setValue(control.value);
      control.markAsDirty();
    });
  }

  public isIOS(): boolean {
    const parser = new UAParser(window.navigator.userAgent);
    const osName = parser.getResult()?.os?.name;
    return osName === "Mac OS" || osName === "iOS";
  }

  public copyToClipboard(value: string, id?: string): void {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        this.toast.show(
          {
            title: this.translate.instant("TOAST.COPY_TO_CLIPBOARD.SUCCESS.TITLE"),
            message: this.translate.instant("TOAST.COPY_TO_CLIPBOARD.SUCCESS.MESSAGE"),
          },
          id,
        );
      })
      .catch(() => {
        this.toast.show(
          {
            title: this.translate.instant("TOAST.COPY_TO_CLIPBOARD.ERROR.TITLE"),
            message: this.translate.instant("TOAST.COPY_TO_CLIPBOARD.ERROR.MESSAGE"),
          },
          id,
        );
      });
  }
}
