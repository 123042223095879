import { Store } from "@ngrx/store";
import { RootState } from "./store";
import * as AppActions from "src/app/store/app/actions";
import { DEFAULT_LANGUAGE, AVAILABLE_LANGUAGES } from "./constants";
import { Languages } from "./enums";
import * as FromRouter from "src/app/store/router/selectors";
import { first, Observable, tap } from "rxjs";

export const initializeApp =
  (store: Store<RootState>): Function =>
    () => {
      try {
        const programSlug$: Observable<string> = store.select(FromRouter.selectProgramSlug);

        programSlug$
          .pipe(
            first(programSlug => !!programSlug),
            tap(programSlug => {
              let language;

              const userLang = navigator.language.split("-")[0] as Languages;

              if (AVAILABLE_LANGUAGES.includes(programSlug)) language = programSlug;
              else if (AVAILABLE_LANGUAGES.includes(userLang)) language = userLang;
              else language = DEFAULT_LANGUAGE;

              store.dispatch(AppActions.changeLanguage({ language }));
            }),
          )
          .subscribe();
      } catch (e) {
        console.error("Setting Language Problem: ", e);
      }
    };
