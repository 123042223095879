import { Component, Inject, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, filter, first, tap } from "rxjs";
import { RootState } from "./store";
import * as UserActions from "src/app/store/user/actions";
import * as AppActions from "src/app/store/app/actions";
import * as FromApp from "src/app/store/app/selectors";
import * as FromRouter from "src/app/store/router/selectors";
import { LoadingController } from "@ionic/angular";
import { Program } from "./services/api";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  private loading: HTMLIonLoadingElement;

  private readonly programSlug$: Observable<string> = this.store.select(FromRouter.selectProgramSlug);
  private readonly queryParams$: Observable<{ referral?: string }> = this.store.select(FromRouter.selectQueryParams);

  private readonly loading$: Observable<boolean> = this.store.select(FromApp.selectLoading);
  private readonly program$: Observable<Program> = this.store.select(FromApp.selectProgram);

  constructor(
    private readonly store: Store<RootState>,
    private readonly loadingCtrl: LoadingController,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.loading = await this.loadingCtrl.create();

    this.programSlug$
      .pipe(
        first(slug => !!slug),
        tap(slug => this.store.dispatch(AppActions.getProgram({ slug }))),
      )
      .subscribe();

    this.loading$
      .pipe(
        tap(async loading => {
          if (loading) this.loading.present();
          else this.loading.dismiss();
        }),
      )
      .subscribe();

    this.program$
      .pipe(
        first(program => !!program?.configurations?.web),
        tap(
          ({
            configurations: {
              web: { backgroundColor, foregroundColor },
            },
          }) => {
            const dark = getComputedStyle(this.document.documentElement).getPropertyValue("--ion-color-dark");
            const darkContrast = getComputedStyle(this.document.documentElement).getPropertyValue("--ion-color-dark-contrast");

            this.document.documentElement.style.setProperty("--ion-color-program-background", backgroundColor || dark);
            this.document.documentElement.style.setProperty("--ion-color-program-foreground", foregroundColor || darkContrast);
          },
        ),
      )
      .subscribe();

    this.queryParams$
      .pipe(
        first(),
        filter(params => !!params?.referral),
        tap(({ referral }) => {
          this.store.dispatch(UserActions.setReferral({ referral }));
        }),
      )
      .subscribe();
  }
}
