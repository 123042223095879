import { Languages } from "src/app/enums";
import { Feature, Program, ProgramIntegration } from "src/app/services/api";

export interface AppState {
  language: Languages;
  program: Program;
  features: Feature[];
  programIntegrations: ProgramIntegration[];
  loading: boolean;
  session: { id: string; dateTime: Date };
}

export const initialState: AppState = {
  language: Languages.en,
  program: null,
  features: [],
  programIntegrations: [],
  loading: false,
  session: { id: null, dateTime: null },
};

export const persistedKeys: (keyof AppState)[] = ["language", "program", "programIntegrations", "features", "session"];
