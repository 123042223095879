import { createAction, props } from "@ngrx/store";
import { Languages } from "src/app/enums";
import { Feature, Program, ProgramControllerGetProgramIntegrationsRequestParams, ProgramIntegration } from "src/app/services/api";

export const changeLanguage = createAction("@app/change-language", props<{ language: Languages }>());
export const changeLanguageSuccess = createAction("@app/change-language-success", props<{ language: Languages }>());

export const getProgram = createAction("@app/get-program", props<{ programId?: string; slug?: string }>());
export const getProgramSuccess = createAction("@app/get-program-success", props<{ program: Program }>());
export const getProgramFailure = createAction("@app/get-program-failure", props<{ error: string }>());

export const getProgramIntegrations = createAction(
  "@app/get-program-integrations",
  props<ProgramControllerGetProgramIntegrationsRequestParams>(),
);
export const getProgramIntegrationsSuccess = createAction(
  "@app/get-program-integrations-success",
  props<{ programIntegrations: ProgramIntegration[] }>(),
);
export const getProgramIntegrationsFailure = createAction("@app/get-program-integrations-failure");

export const getFeatures = createAction("@app/get-features");
export const getFeaturesSuccess = createAction("@app/get-features-success", props<{ features: Feature[] }>());
export const getFeaturesFailure = createAction("@app/get-features-failure");

export const setSessionId = createAction("@app/set-session-id", props<{ sessionId: string }>());
